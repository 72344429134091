import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { auth, db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/form.css';
import Footer from './Footer';

function Form1PC() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [facultyData, setFacultyData] = useState([]);
  const [HODData, setHODData] = useState([]);
  const [hodName, setHodName] = useState('');
  const [facultyName, setFacultyName] = useState('');
  const [year, setYear] = useState('');
  const [department, setDepartment] = useState('');
  let navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
      } else {
        navigate('/');
      }
      setLoading(false);
    });
    return unsubscribe;
  }, [navigate]);

  const fetchHODData = async () => {
    const q = query(collection(db, 'hod'), where('department', '==', department));
    const querySnapshot = await getDocs(q);
    const tempDoc = [];
    querySnapshot.forEach((doc) => {
      tempDoc.push({ ...doc.data(), id: doc.id });
    });
    setHODData(tempDoc);
  }

  useEffect(() => {
    fetchHODData();
  }, [department]);

  const fetchFacultyData = async () => {
    const q = query(collection(db, 'faculty'), where('department', '==', department));
    const querySnapshot = await getDocs(q);
    const facultyDoc = [];
    querySnapshot.forEach((doc) => {
      facultyDoc.push({ ...doc.data(), id: doc.id });
    });
    setFacultyData(facultyDoc);
  }

  useEffect(() => {
    fetchFacultyData();
  }, [department]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   navigate('/form2aprincipal', { state: { facultyUID: facultyData[0].uid } });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedFaculty = facultyData.find(faculty => faculty.name === facultyName && faculty.year === year);
    if (selectedFaculty) {
        navigate('/form2aprincipal', { state: { facultyUID: selectedFaculty.id } });
    } else {
        console.log('No faculty selected or no matching faculty found.');
    }
};


  const filteredHODData = HODData.filter((hod) => hod.year === year && hod.department === department);
  const filteredFacultyData = facultyData.filter((faculty) => faculty.year === year && faculty.department === department);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col md={11} className="mx-auto">
          <h1 className="text-center">Part A: General Information</h1>

          <div className="text-center mb-4">
            <Row>
              <Col>
                <Button variant="primary" type="submit">
                  <Link to="/form1pch" style={{ color: 'white' }}>
                    Go to HOD Forms
                  </Link>
                </Button>
              </Col>
            </Row>
          </div>

          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="year">
              <Row>
                <Col md={3} className="form-label">
                  <Form.Label>Year of Appraisal:</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Control
                    as="select"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value="">Select Year</option>
                    {/* Add more years as needed */}
                    <option value="2022-23">2022-23</option>
                    <option value="2023-24">2023-24</option>
                    <option value="2024-25">2024-25</option>
                    <option value="2025-26">2025-26</option>
                    <option value="2026-27">2026-27</option>
                    <option value="2027-28">2027-28</option>
                    <option value="2028-29">2028-29</option>
                    <option value="2029-30">2029-30</option>
                    <option value="2030-31">2030-31</option>
                    <option value="2031-32">2031-32</option>
                    <option value="2032-33">2032-33</option>
                    <option value="2033-34">2033-34</option>
                    <option value="2034-35">2034-35</option>
                    <option value="2035-36">2035-36</option>
                    <option value="2036-37">2036-37</option>
                    <option value="2037-38">2037-38</option>
                    <option value="2038-39">2038-39</option>
                    <option value="2039-40">2039-40</option>
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="department">
              <Row>
                <Col md={3} className="form-label">
                  <Form.Label>Department:</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Control
                    as="select"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                  >
                    <option value="">Select Department</option>
                    <option value="Basic Sciences & Humanities">Basic Sciences & Humanities</option>
                    <option value="Computer Engineering">Computer Engineering</option>
                    <option value="Artificial Intelligence & Data Science">Artificial Intelligence & Data Science</option>
                    <option value="Information Technology">Information Technology</option>
                    <option value="Electronics & Telecommunication Engineering">Electronics & Telecommunication Engineering</option>
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3 align-item-center" controlId="hodName">
              <Row>
                <Col md={3} className="form-label">
                  <Form.Label>HOD Name:</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Control
                    as="select"
                    value={hodName}
                    onChange={(e) => setHodName(e.target.value)}
                  >
                    <option value="">Select Name</option>
                    {filteredHODData.map((hod, index) => (
                      <option key={index} value={hod.name}>
                        {hod.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>

            {filteredHODData.map((hod, index) => {
              if (hod.name === hodName && hod.year === year) {
                return (
                  <div key={index}>
                    <Form.Group className="mb-3" controlId="name">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Name:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="text" placeholder={hod.name} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="department">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Department:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="text" placeholder={hod.department} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contact">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Contact:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="tel" placeholder={hod.contact} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Email Address:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="email" placeholder={hod.email} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                );
              }
              return null;
            })}

            <Form.Group className="mb-3 align-item-center" controlId="facultyName">
              <Row>
                <Col md={3} className="form-label">
                  <Form.Label>Faculty Name:</Form.Label>
                </Col>
                <Col md={9}>
                  <Form.Control
                    as="select"
                    value={facultyName}
                    onChange={(e) => setFacultyName(e.target.value)}
                  >
                    <option value="">Select Name</option>
                    {filteredFacultyData.map((faculty, index) => (
                      <option key={index} value={faculty.name}>
                        {faculty.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>

            {filteredFacultyData.map((faculty, index) => {
              if (faculty.name === facultyName && faculty.year === year) {
                return (
                  <div key={index}>
                    <Form.Group className="mb-3" controlId="name">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Name:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="text" placeholder={faculty.name} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="department">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Department:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="text" placeholder={faculty.department} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="designation">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Current Designation:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="text" placeholder={faculty.designation} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="DOLpromotion">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Date of Last Promotion:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="text" placeholder={faculty.DOLpromotion} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="address">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Address for correspondence:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="text" placeholder={faculty.address} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contact">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Contact:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="tel" placeholder={faculty.contact} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Email Address:</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="email" placeholder={faculty.email} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="freshqualification">
                      <Row>
                        <Col md={3} className="form-label">
                          <Form.Label>Whether acquired any degrees or fresh qualifications during the year: (Yes/No)</Form.Label>
                        </Col>
                        <Col md={9}>
                          <Form.Control type="text" placeholder={faculty.freshQualification} readOnly />
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                );
              }
              return null;
            })}

            <div className="text-center mb-4">
              <Row>
                <Col>
                  <Button variant="primary" type="submit" onClick={handleSubmit}>
                    <Link className="text-decoration-none text-white">
                      Next
                    </Link>
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Form1PC;

